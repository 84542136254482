<template>
  <div>
    <div
      v-if="!surveySubmitted"
      class="flex flex-col gap-8 bg-silver px-16 py-10"
    >
      <img
        src="@/assets/logos/rushden_horizontal.png"
        class="mx-auto w-1/3"
        alt=""
      />
      <div
        v-if="loading"
        class="animate-pulse flex flex-col justify-center items-center mt-32 w-full"
      >
        <div v-for="id in 5" :key="id">
          <div class="h-10 w-44 bg-gray-400 my-3 rounded-md"></div>
          <div class="flex gap-4">
            <div v-for="id in 5" :key="id">
              <div class="w-80 h-10 bg-gray-400 rounded-md"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="surveyQuestions.length > 0">
          <span class="font-medium">Upload Physical Survey</span>
          <div
            class="flex flex-col gap-10 bg-white my-8 py-12 px-12 shadow-lg rounded-lg border border-grey-200"
          >
            <div
              v-for="(item, questionIndex) in surveyQuestions"
              :key="questionIndex"
              class="flex flex-col gap-8"
            >
              <span class="font-extralight"
                >Q{{ questionIndex + 1 }} - {{ item.question }}</span
              >
              <div
                class="grid grid-cols-5 gap-4 xs:grid-rows-5 xs:grid-cols-1 sm:grid-rows-5 sm:grid-cols-1 md:grid-cols-5 md:grid-rows-1 lg:grid-cols-5 lg:grid-rows-1"
              >
                <div
                  v-for="(option, optionIndex) in options"
                  :key="optionIndex"
                >
                  <input
                    type="radio"
                    :name="questionIndex"
                    :value="option"
                    :id="`${questionIndex}-${optionIndex}`"
                    class="peer hidden"
                    :checked="selectedOption[questionIndex] === option"
                    @change="handleOptionChange(questionIndex, option, item.id)"
                  />

                  <label
                    :for="`${questionIndex}-${optionIndex}`"
                    class="flex cursor-pointer text-left rounded-md border border-textGray-600 bg-white p-4 text-gray-900 hover:border-gray-200 checked:border-teal checked:bg-teal checked:text-white"
                  >
                    <p
                      class="text-sm font-medium"
                      :class="{
                        'text-white':
                          selectedOption[questionIndex] === optionIndex + 1,
                      }"
                    >
                      {{ option }}
                    </p>
                  </label>
                </div>
              </div>
            </div>
            <div class="flex flex-col gap-8">
              <h1 class="font-semibold text-xl">Your feedback here</h1>
              <div>
                <label
                  class="block text-gray-500 text-xs font-bold mb-2"
                  for="question"
                >
                  What was the best highlight of your visit (If you happen to
                  know who helped it make better)?
                </label>
                <input
                  v-model="positiveFeedback"
                  class="appearance-none block w-full h-14 text-gray-700 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                  id="question"
                  type="text"
                  maxlength="200"
                  placeholder="Type here"
                />
                <p class="text-gray-600 text-xs">
                  Type your feedback here between 1 to 200 characters
                </p>
              </div>
              <div>
                <label
                  class="block text-gray-500 text-xs font-bold mb-2"
                  for="question"
                >
                  Is there anything we can do to improve?
                </label>
                <input
                  v-model="negativeFeedback"
                  class="appearance-none block w-full h-14 text-gray-700 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                  id="question"
                  type="text"
                  maxlength="200"
                  placeholder="Type here"
                />
                <p class="text-gray-600 text-xs">
                  Type your feedback here between 1 to 200 characters
                </p>
              </div>
            </div>
          </div>
          <div class="flex justify-end items-center">
            <button
              class="bg-teal h-12 w-34 font-light text-white rounded-md"
              :class="{ 'opacity-50 cursor-not-allowed': disableSubmitted }"
              :disabled="disableSubmitted"
              @click="handleSubmit"
            >
              Submit
            </button>
          </div>
        </div>

        <div v-else class="flex justify-center mt-32 h-screen">
          <div class="font-medium text-gray-500 text-4xl">
            <i class="material-icons text-8xl flex justify-center"
              >error_outline</i
            >
            <div class="mt-5">Sorry! This survey has been expired</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col gap-14 px-16 py-10 justify-content text-center"
    >
      <img
        src="@/assets/logos/rushden_horizontal.png"
        class="mx-auto w-1/3"
        alt=""
      />
      <img
        src="@/assets/logos/excellence.png"
        class="mx-auto"
        alt=""
        height="128"
        width="128"
      />
      <div class="text-teal text-4xl font-bold mt-6">CONGRATULATIONS</div>
      <div class="font-medium text-sm">{{ successMessage }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, reactive, inject } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const route = useRoute();
const toast = inject("toast");

const positiveFeedback = ref("");
const negativeFeedback = ref("");
const surveySubmitted = ref(false);
const disableSubmitted = ref(true);
const successMessage = ref(
  "You have successfully submitted  survey. You may safely close the tab now."
);

const surveyEntry = reactive({
  practice: route.params.practiceId,
  survey: route.params.surveyId,
  instance: route.params.instanceId,
  questions: [],
  feedbacks: [
    {
      type: "positive",
    },
    {
      type: "negative",
    },
  ],
});
const loading = ref(true);

store
  .dispatch(
    "GeneralPublicModule/getPublicSurveyQuestions",
    route.params.surveyId
  )
  .then(() => {
    loading.value = false;
  });

const surveyQuestions = computed(() => {
  return store.getters["GeneralPublicModule/getPublicSurveyQuestions"];
});

const options = [
  "Strongly Disagree",
  "Disagree",
  "Neutral",
  "Agree",
  "Strongly Agree",
];

const selectedOption = ref(Array(surveyQuestions?.value?.length).fill(null));

const handleOptionChange = (questionIndex, option) => {
  const optionMap = {
    "Strongly Disagree": 1,
    Disagree: 2,
    Neutral: 3,
    Agree: 4,
    "Strongly Agree": 5,
  };
  disableSubmitted.value = false;
  selectedOption.value[questionIndex] = optionMap[option];
};

const handleSubmit = () => {
  const mappedQuestions = surveyQuestions.value.map((question, index) => ({
    survey_question: question.id,
    option: selectedOption.value[index],
  }));

  surveyEntry.questions = mappedQuestions;

  const mappedFeedbacks = surveyEntry.feedbacks.map((feedback) => ({
    type: feedback.type,
    text:
      feedback.type == "positive"
        ? positiveFeedback.value
        : negativeFeedback.value,
  }));

  surveyEntry.feedbacks = mappedFeedbacks;

  for (let i = 0; i < surveyEntry.questions.length; i++) {
    if (!surveyEntry.questions[i].option) {
      toast.show(`Please choose an option for Question no: ${i + 1}`);
      return;
    }
  }
  for (let i = 0; i < surveyEntry.feedbacks.length; i++) {
    if (surveyEntry.feedbacks[i].text === "") {
      toast.show(`Please add your feedback(s) as well`);
      return;
    }
  }

  store.dispatch("GeneralPublicModule/submitSurvey", surveyEntry).then(() => {
    surveySubmitted.value = true;
  });
};
</script>
